/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap');

body {
    font-family: "Bodoni Moda", serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.background-filter {
    background-image: url("images/Luke-&-Charlotte-3.png");
    background-repeat: no-repeat;
    background-position-x: -100px;
    background-size: contain;
    background-position: -50px bottom;
    background-attachment: fixed;

}

@media screen and (min-width: 768px) {
    .background-filter {
        background-image: url("images/Luke-&-Charlotte-3.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: 0px;
    }
}

.slide-in-right {
    animation: slide-in-right 0.3s ease forwards;
}

.slide-in-left {
    animation: slide-in-left 0.3s ease forwards;
}
